import CONTRIBUTORS from 'data/CONTRIBUTORS'
import React from 'react'

export const changelog = [
	{
		date: new Date('2021-11-19'),
		Changes: () => <>Initial Dawntrail implementation</>,
		contributors: [CONTRIBUTORS.KELOS],
	},
]
