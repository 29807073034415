import CONTRIBUTORS from 'data/CONTRIBUTORS'
import React from 'react'

export const changelog = [
	{
		date: new Date('2024-07-23'),
		Changes: () => <>Initial support, Addle & Swiftcast still in the past for BLU</>,
		contributors: [CONTRIBUTORS.HUGMEIR],
	},
]
